import * as React from "react";
import { Link } from "gatsby";

const NewsHeadline = ({ node }) => {
  const frontmatter = node.frontmatter;

  return (
    <React.Fragment>
      <dt>{frontmatter.date}</dt>
      <dd>
        <Link to={node.fields.slug}>
          {frontmatter.title}
        </Link>
      </dd>
    </React.Fragment>
  );
};

export default NewsHeadline;
