import * as React from "react";
import { graphql } from "gatsby";
import { Container, Row, Col } from 'react-bootstrap';

import Seo from "../components/seo";
import Layout from "../components/layout";
import NewsHeadline from "../components/parts/news-headline";

import "../styles/global/base.scss";
import "../styles/news.scss";

const NewsPage = ({ data }) => {
  const news = data.allNews.edges;
  return (
    <React.Fragment>
      <Seo title="NEWS" pageClass="newsPage" />
      <Layout>
        <Container fluid className="newsIndexHeader">
          <Row>
            <Col>
              <h1>NEWS</h1>
            </Col>
          </Row>
        </Container>
        <Container fluid>
          <Row>
            <Col md={{ span: 10, offset: 1 }} className="newsListOuter">
              {
                news.map(({ node }) => (
                  <dl>
                    <NewsHeadline node={node} key={node.id} />
                  </dl>
                ))
              }
            </Col>
          </Row>
        </Container>
      </Layout>
    </React.Fragment>
  );
};

export default NewsPage;

export const query = graphql`
  query {
    allNews: allMarkdownRemark(sort: {fields: frontmatter___date, order: DESC}) {
      edges {
        node {
          id
          frontmatter {
            date
            title
          }
          fields {
            slug
          }
        }
      }
    }
  }
`;
